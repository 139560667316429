<template>
  <div>
    <TosListVuetify entityName="occupazioneBinari" :headers="headers"  :excludeButtons="excludeButtons">
      <template #customFilters="slotProps">
        <v-row>
          <v-col cols="2">
            <v-text-field
              label="Convoglio"
              v-model="slotProps.data.convoglio_nome"
              clearable
            />
          </v-col>
           <v-col cols="2">
            <DateTimeAdapter
                label="Data"
                v-model="slotProps.data.dataIn"
                @input="(a) => {slotProps.data.dataIn = a;}"
                clearable
              />
          </v-col>
           <v-col cols="2">
            <v-select
              label="Parco"
              :items="listParchi"
              v-model="slotProps.data.binario_parcoBinari_id"
              item-text="label"
              item-value="id"
              @input="(a) => {parcoBinariId=a}"
              clearable
            />
          </v-col>
           <v-col cols="2">
            <v-select
              label="Binario"
              :items="listBinari"
              :disabled="!slotProps.data.binario_parcoBinari_id"
              v-model="slotProps.data.binario_id"
              item-text="numero"
              item-value="id"
              clearable
            />
          </v-col>
        </v-row>
      </template>
      <template #item.endDate="slotProps">
        {{slotProps.value && slotProps.value.substring(0,4)== '3000' ? "" : slotProps.value}}
      </template>
      <template #item.actions="slotProps">
      </template>
    </TosListVuetify>
  </div>
</template>

<script>
import TosListVuetify from "../../components/anagrafiche/TosListVuetify";
import FormatsMixins from "../../mixins/FormatsMixins";
import DateTimeAdapter from "../../components/anagrafiche/DateTimeAdapter";

export default {
  name: "OccupazioneBinari",
  props: {
  },
  components: {
    TosListVuetify,
    DateTimeAdapter,
  },
  mixins: [FormatsMixins],
  data() {
    return {
      headers: [
        { text: "ID", value: "id" },
        { text: "Inizio", value: "startDate" },
        { text: "Fine", value: "endDate" },
        { text: "Parco", value: "parcoBinari", sortable:false },
        { text: "Binario", value: "binario" },
        { text: "Pos.Inizio", value: "posizioneInizio" },
        { text: "Pos.Fine", value: "posizioneFine" },
        { text: "Convoglio", value: "convoglio.label", sortable:false },
        { text: "Stato", value: "stato" }
      ],
      listParchi:[],
      listBinari:[],
      parcoBinariId: null,
      excludeButtons: {
        add: true,
      }
    };
  },
  watch: {
    parcoBinariId() {
      this.reloadBinari();
    }
  },
  async mounted(){
    this.listParchi=(await this.$api.get(this.$apiConfiguration.BASE_PATH+"parcoBinari/shortList")).data;
    this.reloadBinari();
  },
  methods: {
    async reloadBinari(){
      const listBinariResp=await this.$api.get(this.$apiConfiguration.BASE_PATH+"binari/list", {params: {	filters: {parcoBinari_id___EQ: parseInt(this.parcoBinariId) }}});
      this.listBinari=listBinariResp.data;
    },
  },
  computed: {
  },
};
</script>
